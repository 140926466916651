import { IGridColumn } from '@/interfaces';

export const DefaultColumns: IGridColumn[] = <IGridColumn[]>[
    {
        name: 'id',
        title: 'Video',
        sortField: 'title',
    },
    {
        name: 'studioTitle',
        title: 'Studio',
        hide: false,
    },
    {
        name: 'study',
        title: 'Study',
        hide: false,
    },
    {
        name: 'sid',
        title: 'SID',
    },
    {
        name: 'streamName',
        title: 'File',
    },
    {
        name: 'creationDate',
        title: 'Created',
        callback: 'formatDate',
    },
];
