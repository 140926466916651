export const InitialSort = [
    {
        name: 'studioTitle',
        ascending: true,
    },
    {
        name: 'study',
        ascending: true,
    },
    {
        name: 'streamName',
        ascending: true,
    },
];