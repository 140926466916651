import { Component, Watch } from 'vue-property-decorator';
import Browser from '@/support/browser';
import PlayerApi from '@/support/playerApi';
import ReportPageBase from '@/support/reportPageBase';
import Util from '@/support/utility';
import { AllColumns } from './allColumns';
import { DefaultColumns } from './defaultColumns';
import { IVideoDto, IGridColumn } from '@/interfaces';
import { InitialSort } from './initialSort';

@Component({
    filters: {
        capitalize(str: string) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },

        formatDate(value: string) {
            return Util.formatDate(value);
        }
    }
})
export default class VideosReportComponent extends ReportPageBase {
    options = {
        allData: Browser.getBoolParam('all', false) || Browser.getBoolParam('allData', false),
        limit: Browser.getIntParam('limit', 0),
        filter: Browser.getParam('filter', null),
    };

    $refs: {
        grid: any;
        changeCreationDate: any;
        deletePrompt: any;
    };

    selected = [];
    videos: IVideoDto[] = [];

    columns: IGridColumn[] = DefaultColumns;

    @Watch('state.allData', { immediate: true })
    onAllDataChanged(val: string, oldVal: string) {
        this.updateColumns();
    }

    created() {
        this.reportPageCreated('videoReport', InitialSort, true);
        this.gridColumns = this.columns;

        Debug.setDebugModule('App', this);
    }

    mounted() {
        super.mounted();

        if (this.options.filter)
            this.filterKey = this.options.filter;

        if (this.options.limit)
            this.state.limit = this.options.limit;

        if (this.options.allData)
            this.state.allData = true;

        this.updateColumns();
    }

    beforeDestroy() {
        super.beforeDestroy();
    }

    async getData() {
        return await this.getVideos();
    }

    async getVideos() {
        try {
            let studio = this.studioSelection.selected.studio;

            this.href = null;
            let response = await PlayerApi.videoList(studio, this.state.limit);

            this.videos.splice(0, this.videos.length);

            response.forEach(video => {
                if (video.isLive) return;

                let videoObj = <IVideoDto>video;
                this.setPrivateMembers(videoObj);

                this.videos.push(videoObj);
            });

            Debug.log('getVideos', studio, this.videos.length, 'Videos');

            return true;
        } catch (err) {
            let message = err.message || 'ERROR';

            Util.showError('Failed to get videos list ' + message);
            return null;
        }
    }

    setPrivateMembers(video: IVideoDto) {

        let params = video.id;
        let url = window.location.origin + '/Studio/' + video.studio + '?stream=' + encodeURIComponent(params);

        video.shareUrl = url;

        let studio = this.studios.find(item => item.studio == video.studio);
        if (studio) {
            video.studioTitle = studio.title;
        } else {
            video.studioTitle = video.studio;
        }

        video.captionsStr = video.captions ? video.captions + ' Subtitle(s)' : null;
        video.archivedStr = video.archived ? 'Archived' : null;
        video.playtestStr = video.playtest ? 'Playtest' : null;
        video.uploadedStr = video.uploaded ? 'Uploaded' : null;
    }

    videoUrl(video: IVideoDto) {
        let params = video.study + '/' + video.title;
        let url = window.location.origin + '/Studio/' + video.studio + '?stream=' + encodeURIComponent(params);

        return url;
    }

    deleteSelected() {
        this.$refs.deletePrompt.show();
    }

    // ReSharper disable once InconsistentNaming
    async _deleteSelected() {
        try {
            this.busy = true;

            let selected = this.selected;

            let deletes = [];
            selected.forEach(item => {
                deletes.push(PlayerApi.deleteAdminVideo(item));
            });

            let result = await Promise.all<SUR.VideoDto>(deletes);

            let errors = [];
            let count = 0;
            result.forEach(video => {
                let selectedItem = selected[count++];

                if (video) {
                    let idx = this.videos.indexOf(selectedItem);
                    if (idx != -1) {
                        this.videos.splice(idx, 1);
                        Debug.log('_deleteSelected DELETED', selectedItem, idx);
                        return;
                    }
                }

                errors.push(selectedItem);
                Debug.error('_deleteSelected', selectedItem);
            });

            if (errors.length) {

                Util.showToast('Failed to delete videos', true);
                return false;
            }

            Debug.log('deleteSelected SUCCESS', deletes, result);
            Util.showToast('Deleted videos');

            return true;
        } catch (err) {
            Util.showToast('Failed to delete videos', true);
            return false;
        } finally {
            this.busy = false;
            this.getVideos();
        }
    }

    deletePromptClosed(result) {
        if (result)
            this._deleteSelected();
    }

    async resetAssets() {
        let studio = this.studioSelection.selected.studio;
        await PlayerApi.resetAssets(studio);

        this.getVideos();
    }

    async changeCreationDate(data) {
        this.$refs.changeCreationDate.show(data);
    }

    updateColumns() {
        if (this.state.allData)
            this.columns = AllColumns;
        else
            this.columns = DefaultColumns;

        this.gridColumns = this.columns;
    }

    // base event handlers need to exist in outer class
    onGridDataChanged(count: number) {
        super.onGridDataChanged(count);
    }
}
