import { IGridColumn } from '@/interfaces';

export const AllColumns: IGridColumn[] = <IGridColumn[]>[
    {
        name: 'id',
        title: 'Video',
        sortField: 'title',
    },
    {
        name: 'studioTitle',
        title: 'Studio',
    },
    {
        name: 'study',
        title: 'Study',
    },
    {
        name: 'sid',
        title: 'SID',
    },
    {
        name: 'streamName',
        title: 'File',
    },
    {
        name: 'creationDate',
        title: 'Created',
        callback: 'formatDate',
    },
    {
        name: 'lab',
    },
    {
        name: 'sizeStr',
        title: 'Size',
        sortField: 'size',
    },
    {
        name: 'captionsStr',
        title: 'SubTitled'
    },
    {
        name: 'uploadedStr',
        title: 'Uploaded'
    },
    {
        name: 'playtestStr',
        title: 'Playtest'
    },
    {
        name: 'archivedStr',
        title: 'Archived'
    },
];
